<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
  </div>
</template>

<script>
import config from "@/assets/config";
export default {
  name: "App",
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject接受。
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      //自定义参数
      isShow: true,
    };
  },
  mounted() {
    document.title = config.systemName;
    let favicon = document.querySelector("link[rel='icon']");
    favicon.href = config.pictureSetting.iconHref;
    // 主题变更
    // if (window.location.host === "scrm.jinzhimc.cn") {
    //   // 金致
    //   document.documentElement.style.setProperty("--main-menu-top", "url('./static/theme/jinzhi/home_banner.png')");
    //   favicon.href = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/favicon.ico";
    // } else {
    //   // 欣成旺斯
    //   document.documentElement.style.setProperty("--main-menu-top", "url('./static/theme/xcws/home_banner.jpg')");
    //   favicon.href = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/common/log_home.jpg";
    // }
  },
  created() {

  },
  methods: {
    reload() {
      //reload方法首先将isShow设置为false，将router-view通过if判断取消
      this.isShow = false;
      // Vue.nextTick用于延迟执行一段代码，它接受2个参数（回调函数和执行回调函数的上下文环境），如果没有提供回调函数，那么将返回promise对象。
      this.$nextTick(function () {
        //     在页面更新后再将isShow设置为true
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/*主题样式*/
:root{
  /** 登录首页的top*/
  --main-menu-top: "";
}
/deep/.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
}
/deep/.el-upload-list__item {
  transition: none !important;
  vertical-align: middle;
  text-align: center;
  display: table-cell !important;
}

</style>
