import apiJs from "../assets/js/api";

import {Message} from 'element-ui';

export default {
    get({url, data}) {
        return apiJs.get(url, {
          params: data,
        }).then(data => this.dealData(data));
    },
    post({url, data}) {
        return apiJs.post(url, data).then(data => this.dealData(data));
    },
    getDown({url, data}) {
      return new Promise((resolve, reject) => {
        apiJs({
          method: "get",
          url: url,
          params: data,
          responseType: "blob",
        }).then(res => {
          const blob = new Blob([res.data]);
          blob.text().then((result) => {
            try {
              debugger
              const jsonData = JSON.parse(result);
              this.$message.error(jsonData.message);
              reject(jsonData);
            } catch (e) {
              resolve(result);
            }
          });
        });
      });
    },
    postDown({url, data}) {
      return apiJs({
        method: "post",
        url: url,
        data: data,
        responseType: "blob",
      }).then(res => {
        const blob = new Blob([res.data]);
        blob.text().then((result) => {
          try {
            const jsonData = JSON.parse(result);
            this.$message.error(jsonData.message);
          } catch (e) {
            return res.data;
          }
        });
      });
    },
    del({url, data}) {
        return apiJs.delete(url, {params: data}).then(data => this.dealData(data))
    },
  // 结果统一处理
    dealData(response) {
      if (response.status !== 200) {
        // 请求失败
        // throw new Error();
      }
      if (response.data.code !== "2000") {
        // 接口返回的数据失败
        Message.warning(response.data.message)
        // throw new Error();
      }
      // 接口正常返回
      return response.data.data;
    }
};
