let systemName;
let serviceName;
let questionDefaultPicture; // 问卷调查的默认图片
let homeMainBackground; // 首页，显示在main中的背景图片
let iconHref; // 标题图标，显示在浏览器上的图标
if (window.location.host === "scrm.jinzhimc.cn") {
  systemName = "金致HOME数字化赋能系统";
  serviceName = "jinzhiHome";
  questionDefaultPicture = "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/common/question_default.jpg";
  homeMainBackground = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/home_jinzhi.png";
  iconHref = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/favicon.ico";
} else if (window.location.host === "yun.xcwsmc.com") {
  systemName = "欣专家 云系统";
  serviceName = "xinchengwangsi";
  questionDefaultPicture = "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/common/question_default.jpg";
  homeMainBackground = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/home_xcws.jpg";
  iconHref = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/common/log_home.jpg";
} else {
  systemName = "测试系统";
  serviceName = "xinchengwangsi";
  questionDefaultPicture = "https://jinzhi-scrm.oss-cn-chengdu.aliyuncs.com/common/question_default.jpg";
  homeMainBackground = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/domain/home_xcws.jpg";
  iconHref = "https://xinchengwangsi-scrm.oss-cn-chengdu.aliyuncs.com/common/log_home.jpg";
}
export default {
  systemName: systemName,
  serviceName: serviceName,
  filePath: "https://file.jinzhimc.cn/",
  pictureSetting: {
    // 问卷调查的默认图片
    questionDefaultPicture,
    // 首页，显示在main中的背景图片
    homeMainBackground,
    // 标题图标，显示在浏览器上的图标
    iconHref,
  }
}
